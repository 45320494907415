import React from 'react';
import { useHistory } from 'react-router-dom';
//import { browserHistory } from 'react-router';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import WNLogo from '../../assets/WNLogo.png';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://wellneed.my/">
                WellNeed
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    large: {
        width: theme.spacing(16),
        height: theme.spacing(16),
    },
    spaceTop: {
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 3),
    },

}));

export default function LoginScreen() {
    const history = useHistory();
    const classes = useStyles();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    function setCookie(cname, cvalue, exdays) {
        const date = new Date();
        date.setTime(date.getTime() + (exdays*24*60*60*1000));
        let expires = "expires=" + date.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    let user = getCookie("email");
    if (user === 'hengjiasen@gmail.com') {
        history.push('/dashboard');
    } else {
        // do nothing
    }

    const loginValidation = () => {
        if (email === '' || password === '') {
            alert('Please fill in login details.');

        } else if (/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email) === false) {
            alert('Wrong email format. Please try again');

        } else if (email === 'hengjiasen@gmail.com' && password === '12345678') {
            setCookie("email", email, 30);
            history.push('/dashboard');

        } else {
            alert('Wrong email or password. Please try again.');
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar alt="logo" src={WNLogo} className={classes.large}/>
                <Typography component="h1" variant="h4" className={classes.spaceTop}>
                    Login
                </Typography>
                <form className={classes.form}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="phone"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        //autoFocus
                        onChange={event => setEmail(event.target.value.replace(/\s/g,''))}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        //type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={event => setPassword(event.target.value.replace(/\s/g,''))}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary"/>}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        //onClick={() => history.push('/Dashboard')}
                        onClick={loginValidation}
                    >
                        Login
                    </Button>
                    <Grid container justify={"space-between"}>
                        <Grid item>
                            <Link variant="body2" onClick={() => history.push('/forget_password')} >
                                {"Forget password?"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={8}>
                <Copyright/>
            </Box>
        </Container>
    );
}