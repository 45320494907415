import React from 'react';
import { useHistory } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AdminsIcon from '@material-ui/icons/Person';
import MerchantsIcon from '@material-ui/icons/Storefront';
import VouchersIcon from '@material-ui/icons/Loyalty';
import UsersIcon from '@material-ui/icons/People';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export default function ListItems() {
    const history = useHistory();

    function setCookie(cname, cvalue, exdays) {
        const date = new Date();
        date.setTime(date.getTime() - (exdays*24*60*60*1000));
        let expires = "expires=" + date.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    const logOut = () => {
        setCookie("email", 'hengjiasen@gmail.com', 60);
        history.push('/');
    }

    return (
        <div>
            <ListItem button onClick={() => history.push('/dashboard')}>
                <ListItemIcon>
                    <DashboardIcon/>
                </ListItemIcon>
                <ListItemText primary="Dashboard"/>
            </ListItem>
            <ListItem button onClick={() => history.push('/admins')}>
                <ListItemIcon>
                    <AdminsIcon/>
                </ListItemIcon>
                <ListItemText primary="Admins"/>
            </ListItem>
            <ListItem button onClick={() => history.push('/merchants')}>
                <ListItemIcon>
                    <MerchantsIcon/>
                </ListItemIcon>
                <ListItemText primary="Merchants"/>
            </ListItem>
            <ListItem button onClick={() => history.push('/vouchers')}>
                <ListItemIcon>
                    <VouchersIcon/>
                </ListItemIcon>
                <ListItemText primary="Vouchers"/>
            </ListItem>
            <ListItem button onClick={() => history.push('/users')}>
                <ListItemIcon>
                    <UsersIcon/>
                </ListItemIcon>
                <ListItemText primary="Users"/>
            </ListItem>
            <ListItem button onClick={logOut}>
                <ListItemIcon>
                    <ExitToAppIcon/>
                </ListItemIcon>
                <ListItemText primary="Log Out"/>
            </ListItem>
        </div>
    )
}