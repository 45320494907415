import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";

import LoginScreen from "./screens/login/LoginScreen";
import ForgetPasswordScreen from "./screens/login/ForgetPasswordScreen";
import SetPasswordScreen from "./screens/login/SetPasswordScreen";
import Dashboard from "./screens/home/Dashboard";
import Admins from "./screens/home/AdminsList";
import Merchants from "./screens/home/MerchantsList";
import Vouchers from "./screens/home/VouchersList";
import Users from "./screens/home/UsersList";
import history from './history';

export default class Routes extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/" exact component={LoginScreen} />
                    <Route path="/forget_password" component={ForgetPasswordScreen} />
                    <Route path="/set_new_password" component={SetPasswordScreen} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/admins" component={Admins} />
                    <Route path="/merchants" component={Merchants} />
                    <Route path="/vouchers" component={Vouchers} />
                    <Route path="/users" component={Users} />

                </Switch>
            </Router>
        )
    }
}